import classNames from 'classnames'
import { HTMLInputTypeAttribute } from 'react'
import { useField } from 'remix-validated-form'
import FormInputWrapper, { BaseInputClasses, FormInputWrapperProps } from './FormInputWrapper'

export type FormInputProps = {
  type?: HTMLInputTypeAttribute
  disabled?: boolean
  readOnlyAsLabel?: boolean
  labelClassName?: string
  errorClassName?: string
  icon?: React.ReactElement
} & FormInputWrapperProps<HTMLInputElement>

export default function FormInput({
  name,
  label,
  type = `text`,
  placeholder,
  disabled,
  cols,
  readOnlyAsLabel,
  className,
  wrapperClassName,
  labelClassName,
  errorClassName,
  icon,
  showErrorIcon,
  newLineError,
  error,
  onChange,
  ...props
}: FormInputProps) {
  const { error: err, getInputProps } = useField(name)
  const inputClasses = classNames(
    className,
    BaseInputClasses,
    disabled &&
      (readOnlyAsLabel
        ? `border-trn-200 shadow-elevation-1 read-only:cursor-not-allowed read-only:border-none read-only:p-0 read-only:shadow-none read-only:text-trn-500 read-only:focus:text-trn-500`
        : `border-trn-200 shadow-elevation-1 read-only:cursor-not-allowed read-only:border-gray-200 read-only:bg-trn-100 read-only:text-trn-500 read-only:focus:text-trn-500`),
  )
  return (
    <>
      <FormInputWrapper
        {...{
          name,
          label,
          error: err ?? error,
          showErrorIcon,
          newLineError,
          cols,
          wrapperClassName,
          className: labelClassName ?? `text-ui-sm`,
          errorClassName,
          defaultValue: props.defaultValue,
        }}
      >
        <div className="relative">
          {icon && <div className="absolute top-3 left-2 h-full w-6 text-body-sm leading-5">{icon}</div>}
          <input
            defaultValue={props.defaultValue}
            {...getInputProps({
              ...props,
              type: type,
              placeholder,
              readOnly: disabled,
              onChange: onChange,
              required: undefined,
            })}
            className={classNames(inputClasses, icon && `pl-[40px]`, `bg-white`)}
          />
        </div>
      </FormInputWrapper>
    </>
  )
}
